import React from 'react';

import SearchCard from '../../Cards/SearchCard/SearchCard'

export default function SearchCardRow() {
  return (
      <div className="row margin">
    <SearchCard/>
    <SearchCard/>
    <SearchCard/>
    <SearchCard/>
    <SearchCard/>
    </div>
  );
}
