import React from 'react';

import './style.css'
export default function CollectionCard() {
  return (
    <div className="collectioncardcontainer">
        <img alt="album" src="https://upload.wikimedia.org/wikipedia/pt/8/86/Chelsea_Grin_-_Ashes_to_Ashes.jpg"></img>
    </div>
  );
}
