import React from 'react';
import './style.css';

export default function SearchCard() {
  return (
    <div className="searchcardcontainer">
        <h1>Podcasts</h1>
    </div>
  );
}
